import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Modal, Box, Typography } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
//** local files */
import { authAPI } from "../../services/authAPI";
import "./CustomModal.css";
const columns = [
  {
    field: "name",
    headerName: "Folder Name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 200,
  },
  {
    field: "created_at",
    headerName: "Created At",
    width: 250,
    valueGetter: (params) => {
      var date = new Date(params.row?.created_at);
      date.toString();
      return `${date || ""}`;
    },
  },
];

function MoveFilePopup({
  open,
  onClose,
  folderList,
  selectedFile,
  responseStatus,
}) {
  const [isLoading, setLoading] = useState(false);
  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );
  const fileInputEl = useRef(null);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "470px",
    width: "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
    blur: "8px",
    opacity: 2,
    justifyItems: "center",
    alignItems: "center",
  };

  const handleClose = () => {
    onClose();
    fileInputEl.current = null;
  };

  const handleMoveFileToFolder = useCallback(async () => {
    setLoading(true);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
      allowOutsideClick: false,
      showCloseButton: true,
    });
    if (fileInputEl.current === null) {
      toast.warning(`Please select a folder!`);
      await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds for the toast to disappear
      setLoading(false); // Enable the button
      return;
    }
    const data = new FormData();
    data.append("folder_id", fileInputEl.current?.id);
    data.append("file_id", selectedFile?.id);
    const response = await authAPI.MoveSelectedFileToFolder({access_token,data});
    if (response?.status) {
      onClose();
      swalWithBootstrapButtons
        .fire({
          title: "File Moved!",
          text: `Your file ${selectedFile?.name} has been moved to ${fileInputEl.current?.row?.name} successfully.`,
          icon: "success",
        })
        .then((result) => {
          if (result.isConfirmed) {
            responseStatus(fileInputEl.current?.row);
            setLoading(false);
          } else {
            toast.error(response.message || "An error occurred while moving the file.");
            setLoading(false);
            // window.location.reload();
          }
        });
      setLoading(false);
    } else {
      toast.error(response.message || "An error occurred while moving the file.");
      setLoading(false);
    }
  }, [selectedFile, access_token, onClose, responseStatus]);
  
  return (
    <Modal
      className="movefile_popup"
      open={open}
      aria-labelledby="file-upload-modal"
      aria-describedby="file-upload-description"
    >
      <Box sx={style} elevation={8} className="movefile_inner">
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            flexDirection: "row",
          }}
        >
          <Typography id="file-upload-modal" variant="h9" component="h2">
            Move File to Folder
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
              marginBottom: "0",
            }}
          >
            {/* <TextField
              variant="outlined"
              size="small"
              placeholder="Search...."
              style={{
                position: "absolute",
                top: "25px",
                right: "100px",
                left: "200px",
              }}
            /> */}
          </div>
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
        </div>
        <Box
          className="movefile_box"
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F9F4EF",
            background:
              "linear-gradient(0deg, #F9F4EF, #F9F4EF) linear-gradient(0deg, #FF6B04, #FF6B04)",

            alignItems: "center",
            border: "1px dashed orange",
            height: "320px",
            marginTop: "1rem",
            borderRadius: 2,
            cursor: "pointer",
          }}
        >
          <div
            className="muidatagrid_box"
            style={{ height: "320px", width: "100%" }}
          >
            <DataGrid
              rows={folderList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              onRowClick={(event) => (fileInputEl.current = event)}
            />
          </div>
        </Box>
        {isLoading ? (
          <LoadingButton
            loading
            loadingIndicator="Loading…"
            variant="contained"
            style={{ position: "absolute", bottom: "25px", right: "35px" }}
          >
            Fetch data
          </LoadingButton>
        ) : (
          <Button
            className="move_submit_btn"
            variant="outlined"
            onClick={handleMoveFileToFolder}
            style={{ position: "absolute", bottom: "25px", right: "35px" }}
          >
            Submit
          </Button>
        )}
      </Box>
    </Modal>
  );
}

export default MoveFilePopup;
