import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import mapDataWorld from '@highcharts/map-collection/custom/world.geo.json';
import { shallowEqual, useSelector } from 'react-redux';
import { authAPI } from '../../../services/authAPI';
import { toast } from 'react-toastify';
import { Box, Button, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import { countryNameMapping } from '../AnalyticalCommon';
import { countryList } from '../AnalyticalCommon';
import InfoIcon from '@mui/icons-material/Info';
import { useLocation } from 'react-router-dom';
import { useGlobalContext } from '../../../hook/globalstate';
const MapNewComponent = ({ widgetName, widgetTitle, widgetData, leftItem = null, helpText, viewHelpText, hideInfo = false, dropKey, settingId, isPrint = false }) => {
  const name = widgetName;
  const title = widgetTitle;
  const [multipleCountriesData, setMultipleCountriesData] = useState(widgetData || null);
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
  const hasFetched = useRef(false);
  const [loading, setLoading] = useState(false);
  const [isLegendMinimized, setIsLegendMinimized] = useState(false);
  const countryName = countryNameMapping();
  const countryMap = countryList();
  const [isFetched, setIsFetched] = useState(false);
  const location = useLocation();
  const { state, setState } = useGlobalContext()
  const pathType = location.pathname;
  
  useEffect(() => {
    window.addEventListener('filterUpdate', fetchMultipleCountries);
    window.addEventListener('currencyUpdate', fetchMultipleCountries);

    return () => {
      window.removeEventListener('filterUpdate', fetchMultipleCountries);
      window.removeEventListener('currencyUpdate', fetchMultipleCountries);
    };
  }, []);

  const currencySymbol = window.localStorage.getItem('currencySymbol');
  const fetchMultipleCountries = async () => {
    setLoading(true);
    try {
      const filters = window.localStorage.getItem('filters');
      const viewCurrency = window.localStorage.getItem('viewCurrency');
      const formData = new FormData();
      formData.append('name', name);
      if (filters !== '' && filters !== null && filters !== undefined)
        formData.append('filters', filters);
      formData.append('viewCurrency', viewCurrency || 'GBP');
      const response = await authAPI.fetchInfoDisplayWidget({ access_token, formData });
      const dataWithFullNames = response?.result?.map(item => {
        return {
          ...item,
          tooltipName: countryName[item?.tooltipName] || item?.tooltipName
        };
      });
      setMultipleCountriesData(dataWithFullNames || []);
      setIsFetched(true);
    } catch (error) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (access_token && !hasFetched.current && !widgetData) {
      fetchMultipleCountries();
      hasFetched.current = true;
    }
    if (widgetData) {
      const dataWithFullNames = widgetData?.map(item => ({
        ...item,
        tooltipName: countryName[item.tooltipName] || item.tooltipName
      }));
      setMultipleCountriesData(dataWithFullNames);
    }
  }, [access_token, widgetData]);

  useEffect(() => {
    if (leftItem !== null && leftItem?.[0]?.item === widgetName) {
      fetchMultipleCountries();
    }
  }, [leftItem])


  function formatNumber(value) {
    return value?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3
    });
  }

  const normalizeCountryNames = (data) => {
    const countryMapping = countryMap

    return data.map(item => ({
      ...item,
      tooltipName: countryMapping[item.tooltipName] || item.tooltipName
    }));
  };

  const options = {
    chart: {
      map: mapDataWorld,
      animation: false // Disable animation
    },
    title: {
      text: '',
    },
    mapNavigation: {
      enabled: true,
      enableButtons: false,
    },
    colorAxis: {
      min: 0,
      stops: [
        [0, '#EFEFFF'],
        [0.5, '#4444FF'],
        [1, '#000022']
      ]
    },
    series: [{
      data: multipleCountriesData ? normalizeCountryNames(multipleCountriesData).map(item => {
        return {
          name: item.tooltipName,
          value: item.value,
          color: '#f3a670'
        };
      }) : [],
      mapData: mapDataWorld,
      joinBy: ['name', 'name'],
      name: 'Value',
      states: {
        hover: {
          color: '#1b425e'
        }
      },
      dataLabels: {
        enabled: true,
        format: '{point.name}'
      },
      tooltip: {
        pointFormatter: function () {
          const val= (name === "widgets.imports.declarantsByDispCtry" || name === "widgets.imports.declarantsByOrigCtry" || name === "widgets.imports.importValByOrigCtry" || name === "widgets.imports.importsByDispCtry" || name === 'widgets.exports.noOfExportsGoingToDestinations') ? this.value : (currencySymbol || '£')+formatNumber(this.value)
          return `<span>${this.name}: ${val}</span>`;
        },
        headerFormat:''
      }
    }]
  };

  const legendItems = multipleCountriesData ? multipleCountriesData.map(item => (
    <Box key={item?.tooltipName} className='map-legend-row'>
      <Box className='map-legend-row-fli'>
        {item?.tooltipName}
      </Box>
      <Box className='map-legend-row-sli'>
        {(name === "widgets.imports.declarantsByDispCtry" || name === "widgets.imports.declarantsByOrigCtry" || name === "widgets.imports.importValByOrigCtry" || name === "widgets.imports.importsByDispCtry" || name === 'widgets.exports.noOfExportsGoingToDestinations') ? (
          //`${formatNumber(item?.value)}`
          `${item?.value}`
        ) : (
          <>
            <span style={{ fontWeight: '500' }}>{(currencySymbol || '£')}</span>


            {formatNumber(item?.value)}
          </>
        )}

      </Box>
    </Box>
  )) : [];

  if (loading) {
    return <div>
      <Box className={`item-content ${isPrint ? "print" : ""}`} sx={{ paddingTop: '160px', height: '400px' }}>
        <Box className="dashboard-item">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress sx={{ color: '#ff6b04' }} />
          </Box>
        </Box>
      </Box>
    </div>;
  }

  if (!multipleCountriesData) {
    return null;
  }

  if (isFetched && !multipleCountriesData.length) {
    return (
      <Box className="item-content" sx={{ height: '400px' }}>
        <Typography sx={{ textAlign: 'left', fontWeight: '600', padding: '5px 10px', fontSize: '20px' }}>
          {widgetTitle}
        </Typography>
        <Box className="dashboard-item">
          <Typography variant="h6" sx={{ textAlign: 'center', p: 20 }}>
            No result found
          </Typography>
        </Box>
      </Box>
    );
  }

  let widgetSettings = state.settingData[`${settingId}-${dropKey}`];
  return (
    <div>
      <Box className={`item-content tooltip-outer viewChart ${isPrint ? "print" : ""}`} sx={{ padding: '1em', position: 'relative'}}>
        {pathType === '/analyticals' && viewHelpText ? null : ((pathType === '/analyticals') ? (
          <Tooltip title={helpText} placement="right" className='info-tooltip'>
            <IconButton sx={{ p: '0' }}>
              <InfoIcon sx={{ color: '#000' }} />
            </IconButton>
          </Tooltip>
        ) : null
        )}
        {/* <Typography sx={{ textAlign: 'left', fontWeight: '600', padding: '5px 10px', fontSize: '20px' }}>
          {widgetTitle}
        </Typography> */}
        {isFetched && multipleCountriesData?.length === 0 ? (
          <Box className="dashboard-item">
            <Typography variant="h6" sx={{ textAlign: 'center', p: 20 }}>
              No result found
            </Typography>
          </Box>
        ) : (
          <>
            <Typography sx={{ textAlign: 'left', fontWeight: '600', padding: '1px 2px 15px', fontSize: '20px' }}>{widgetSettings?.hideLabel ? '' : widgetSettings?.label ? widgetSettings.label : title} </Typography>
            
            <Box sx={{height:'325px', overflow:'auto'}}>
              
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={'mapChart'}
                options={options}
              />
              <Box className='map-legend'>
                <Button onClick={() => setIsLegendMinimized(!isLegendMinimized)} style={{ background: 'none', border: 'none', cursor: 'pointer', float: 'right', padding: '0px 0px 10px 0px', minWidth: '40px' }}>
                  <FontAwesomeIcon icon={faWindowMinimize} />
                </Button>
                {!isLegendMinimized && (
                  <>
                    <Box className='map-legend-heading'>
                      Legend
                    </Box>
                    <Box className="map-legend-body">
                      <ul style={{ padding: '0', margin: '0', listStyle: 'none' }}>
                        {legendItems}
                      </ul>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </>
        )}
        {pathType === '/analyticals' && viewHelpText ? (
          <p className='printInfoText'>{viewHelpText}</p>
        ) : null}
      </Box>
    </div>
  );
};

export default MapNewComponent;
