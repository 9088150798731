import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DatePeriodsComponent from './datePeriod';
import { DialogActions, Grid, TextField } from '@mui/material';
import CurrencyFilters from './currencys';
import CountryFilters from './countries';
import { shallowEqual, useSelector } from 'react-redux';
import { authAPI } from '../../../services/authAPI';

export default function AddFilter({ open, handleClose, filterResult }) {
  const [filterData, setFilterData] = useState('');
  const [filterableFields, setFilterableFields] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
  const hasFetched = useRef(false);
  const [filtered, setFiltered] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [currencyFilter, setCurrencyFilter] = useState(null);
  const [countryFilter, setCountryFilter] = useState(null);

  const fetchFilterFields = async () => {
    try {
      const selectedWidgets = window.localStorage.getItem('widgetNames')
      const response = await authAPI.filterFields({ access_token, selectedWidgets });
      setFilterableFields(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (access_token && !hasFetched.current) {
      fetchFilterFields();
      hasFetched.current = true;
    }
  }, [access_token]);

  const handleFilterChange = (event) => {
    setFilterData(event.target.value);
    setInputValue('');
    setDateFilter(null);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddFilter = async () => {
    const selectedField = filterableFields?.find(field => field?.column === filterData);
    if (selectedField) {
      const newFilter = {
        column: selectedField?.column,
        columnDisplay: selectedField?.columnDisplay,
        // value: selectedField?.type === 'date' ? dateFilter : { type: selectedField?.type, value: inputValue }
        value: (() => {
          if (selectedField?.type === 'date') {
            return dateFilter;
          } else if ((selectedField?.type === 'currency')) {
            return currencyFilter;
          } else if ((selectedField?.type === 'country')) {
            return countryFilter;
          }  else {
            return { type: selectedField?.type, value: inputValue };
          }
        })()
      };
      
      setFiltered([...filtered, newFilter]);
      setFilterData('');
      setInputValue('');
      setDateFilter(null);
      filterResult({ filters: [...filtered, newFilter] });
    }
  };

  const handleDateChange = (dateFilter) => {
    setDateFilter(dateFilter);
  };
  const handleCurrencyChange = (currencyFilter) => {
    setCurrencyFilter(currencyFilter);
  };
  const handleCountryChange = (countryFilter) => {
    setCountryFilter(countryFilter);
  };


  const selectedField = filterableFields?.find(field => field?.column === filterData);

  const isAddFilterDisabled = () => {
    if (selectedField?.type === 'string') {
      return !inputValue;
    }
    if (selectedField?.type === 'date') {
      return !dateFilter || !dateFilter?.dateType || 
        (dateFilter?.dateType === 'custom' && (!dateFilter?.startDate || !dateFilter?.endDate));
    }
    if (selectedField?.type === 'currency') {
      return !currencyFilter;
    }
    if (( selectedField?.type === 'country')) {
      return !countryFilter;
    }
    return true;
  };
  

  return (
    <Dialog fullWidth={true} maxWidth='md' open={open} onClose={handleClose}  className='filterPopup' sx={{maxWidth:'430px',margin:'auto',width:'100%'}}>
      <DialogTitle sx={{ borderBottom: 'solid 1px #c2c2c2', padding: '15px 15px 10px', marginBottom: '15px' }}>Add Filter</DialogTitle>
      <DialogContent sx={{padding:'8px'}}>
        <Grid
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <FormControl variant="filled" sx={{ minWidth: 200, fontSize:'12px', padding:'0px 5px 5px 5px',width:'100%'}} >
            {/* <InputLabel htmlFor="max-width">Column</InputLabel> */}
            <Select autoFocus value={filterData} onChange={handleFilterChange} label="Column" >
              {filterableFields?.length > 0 && filterableFields?.map(field => (
                <MenuItem key={field?.column} value={field?.column} className='filter-item'>
                  {field?.columnDisplay}
                </MenuItem>
              ))}
            </Select>
            
          </FormControl>

          {/* <FormControl variant="filled" sx={{ minWidth: 200 }} className='columnselect'>
            <Select
              displayEmpty
              value={filterData}
              onChange={handleFilterChange}
              sx={{ fontSize: '13px' }}
            >
              <MenuItem value="" disabled>
                Column
              </MenuItem>
              {filterableFields?.length > 0 && filterableFields.map((field) => (
                <MenuItem className='columnlist' key={field?.column} value={field?.column}>
                  {field?.columnDisplay}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {selectedField?.type === 'date' && <FormControl variant="filled" sx={{ minWidth: 200, fontSize:'12px', padding:'0px 5px 5px 5px',width:'100%'}} ><DatePeriodsComponent onDateChange={handleDateChange} /></FormControl>}
          {(selectedField?.type === 'currency') && <FormControl variant="filled" sx={{ minWidth: 200, fontSize:'12px', padding:'0px 5px 5px 5px',width:'100%'}} ><CurrencyFilters onCurrencyChange = {handleCurrencyChange}/></FormControl>}
          {(selectedField?.type === 'country') && <FormControl variant="filled" sx={{ minWidth: 200, fontSize:'12px', padding:'0px 5px 5px 5px',width:'100%'}} ><CountryFilters onCountryChange = {handleCountryChange}/></FormControl>}
          {selectedField?.type === 'string' && (
            <FormControl variant="filled" sx={{ minWidth: 200, fontSize:'12px', padding:'0px 5px 5px 5px',width:'100%'}} >
            <TextField
              id="filled"
              sx={{height:'56px'}}
              placeholder="Value"
              variant="filled"
              value={inputValue}
              onChange={handleInputChange}
            /></FormControl>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className="btn-red"
          sx={{ backgroundColor: '#be0000', color: '#fff', ml: '5px', textTransform: 'capitalize' }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          sx={{ backgroundColor: '#0000001f', color: '#111', ml: '5px', textTransform: 'capitalize' }}
          onClick={handleAddFilter}
          disabled={isAddFilterDisabled()}
        >
          Add Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}
