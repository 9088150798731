import React, { useCallback, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { shallowEqual, useSelector } from 'react-redux';
import { authAPI } from '../../../services/authAPI';
import { toast } from 'react-toastify';
import { Box, CircularProgress, Typography, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { useGlobalContext } from '../../../hook/globalstate';

const LineChartNewComponent = ({ widgetName, widgetTitle, widgetData, leftItem = null, helpText, viewHelpText, hideInfo = false, dropKey, settingId, isPrint = false, currencyCode  }) => {
    const [newLineChartData, setNewLineChartData] = useState(widgetData || []);
    const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
    const userProfile = useSelector((state) => state.userProfile);
    const [loading, setLoading] = useState(false);
    const hasFetched = useRef(false);
    const [isFetched, setIsFetched] = useState(false);
    const location = useLocation();
    const { state, setState } = useGlobalContext()
    const pathType = location.pathname;
    const [userCurrency, setUserCurrency] = useState();
    
      const fetchCurrencies = useCallback(async () => {
        try {
          const currenciesData = await authAPI.getCurrencies({access_token});
         
          const widgetCurrency = currenciesData.find(
            (ct) => ct.currencyCode === currencyCode
          );

          const userCurrency = currenciesData.find(
            (ct) => ct.currencyCode === userProfile.currency
          );
          setUserCurrency(widgetCurrency || userCurrency || "");
        } catch (error) {
          console.error("Error fetching country list:", error);
        }
      }, [userProfile.currency]);
    
      useEffect(() => {
        fetchCurrencies();
      }, [fetchCurrencies]);

    function formatNumber(value) {
        return value?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    useEffect(() => {
        window.addEventListener('filterUpdate', fetchLineChartData);
        window.addEventListener('currencyUpdate', fetchLineChartData);

        return () => {
            window.removeEventListener('filterUpdate', fetchLineChartData);
            window.removeEventListener('currencyUpdate', fetchLineChartData);
        };
    }, []);

    const currencySymbol = window.localStorage.getItem('currencySymbol');

    const fetchLineChartData = async () => {
        setLoading(true);
        try {
            const filters = window.localStorage.getItem('filters');
            const viewCurrency = window.localStorage.getItem('viewCurrency');
            const formData = new FormData();
            formData.append('name', widgetName);
            if (filters !== '' && filters !== null && filters !== undefined)
                formData.append('filters', filters);

            formData.append("viewCurrency", (viewCurrency || (currencyCode !== 'null' ? currencyCode : '') || userProfile?.currency || "GBP"));
            formData.append("userCurrency", (userProfile?.currency || "GBP"));
            const response = await authAPI.fetchInfoDisplayWidget({ access_token, formData });
            setNewLineChartData(response?.result || []); // Set the fetched data
            setIsFetched(true);
        } catch (error) {
            toast.error(error?.message);
        }
        setLoading(false);
    };

      useEffect(() => {
        if (currencyCode) {
            fetchLineChartData();
        }
      }, [currencyCode]);

        useEffect(() => {
          if (window.localStorage.getItem("viewCurrency") || userProfile?.currency) {
            fetchLineChartData();
          }
        }, [window.localStorage.getItem("viewCurrency"), userProfile?.currency]);

    useEffect(() => {
        if (access_token && !hasFetched.current && !widgetData) {
            fetchLineChartData();
            hasFetched.current = true; // Set the flag to true after the first call
        }
    }, [access_token, widgetData]);

    useEffect(() => {
        if (leftItem !== null && leftItem?.[0]?.item === widgetName) {
            fetchLineChartData();
        }
    }, [leftItem])

    useEffect(() => {
        // Animation logic
        (function (H) {
            const animateSVGPath = (svgElem, animation, callback = void 0) => {
                if (!svgElem || !svgElem.element) return;
                const length = svgElem.element.getTotalLength();
                svgElem.attr({
                    'stroke-dasharray': length,
                    'stroke-dashoffset': length,
                    opacity: 1
                });
                svgElem.animate({
                    'stroke-dashoffset': 0
                }, animation, callback);
            };

            H.seriesTypes.line.prototype.animate = function (init) {
                const series = this,
                    animation = H.animObject(series.options.animation);
                if (!init && series.graph) {
                    animateSVGPath(series.graph, animation);
                }
            };

            H.addEvent(H.Axis, 'afterRender', function () {
                const axis = this,
                    chart = axis.chart,
                    animation = H.animObject(chart.renderer.globalAnimation);

                if (axis.axisGroup) {
                    axis.axisGroup
                        .attr({
                            opacity: 0,
                            rotation: -3,
                            scaleY: 0.9
                        })
                        .animate({
                            opacity: 1,
                            rotation: 0,
                            scaleY: 1
                        }, animation);
                }

                if (axis.horiz && axis.labelGroup) {
                    axis.labelGroup
                        .attr({
                            opacity: 0,
                            rotation: 3,
                            scaleY: 0.5
                        })
                        .animate({
                            opacity: 1,
                            rotation: 0,
                            scaleY: 1
                        }, animation);
                } else if (axis.labelGroup) {
                    axis.labelGroup
                        .attr({
                            opacity: 0,
                            rotation: 3,
                            scaleX: -0.5
                        })
                        .animate({
                            opacity: 1,
                            rotation: 0,
                            scaleX: 1
                        }, animation);
                }
            });
        }(Highcharts));
    }, []);

    // Parse the chart data dynamically from the fetched response
    const categories = newLineChartData.map(item => moment(item.year_month, "YYYY-MM").format("MMM YYYY"));
    const totalDutyPaidSeries = newLineChartData.map(item => parseFloat(item['Total Duty Paid'])); // Total Duty Paid
    const totalCustomsValueSeries = newLineChartData.map(item => parseFloat(item['Total Customs Value'])); // Total Customs Value
    const effectiveDutyRateSeries = newLineChartData.map(item => parseFloat(item['Effective Duty Rate'])); // Effective Duty Rate

    if (loading) {
        return (
            <div>
                <Box className={`item-content ${isPrint ? "print" : ""}`} sx={{ paddingTop: '160px', height: '400px' }}>
                    <Box className="dashboard-item">
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress sx={{ color: '#ff6b04' }} />
                        </Box>
                    </Box>
                </Box>
            </div>
        );
    }

    if (isFetched && !newLineChartData.length) {
        return (
            <Box className="item-content" sx={{ height: '400px' }}>
                <Typography sx={{ textAlign: 'left', fontWeight: '600', padding: '5px 10px', fontSize: '20px' }}>
                    {widgetTitle}
                </Typography>
                <Box className="dashboard-item">
                    <Typography variant="h6" sx={{ textAlign: 'center', p: 20 }}>
                        No result found
                    </Typography>
                </Box>
            </Box>
        );
    }

    let widgetSettings = state.settingData[`${settingId}-${dropKey}`];

    const options = {
        chart: {
            type: 'line', // Using 'line' for zigzag lines
            animation: false // Disable animation for the entire chart
        },

        title: {
            text: widgetSettings?.hideLabel ? '' : widgetSettings?.label ? widgetSettings.label : widgetTitle,
            align: 'left'
        },

        subtitle: {
            text: '',
            align: 'left'
        },

        xAxis: {
            categories: categories // Dates for the x-axis
        },

        yAxis: [{
            title: {
                text: 'Total Duty Paid'
            },
        }, {
            opposite: true,
            title: {
                text: 'Total Customs Value'
            }
        }, {
            opposite: true,
            title: {
                text: 'Effective Duty Rate (%)'
            }
        }],

        plotOptions: {
            series: {
                animation: false, // Disable animation for series
                marker: {
                    enabled: false
                },
                lineWidth: 2,
                linecap: 'square' // Make the line appear more angular (zigzag)
            }
        },

        // Tooltip configuration
        tooltip: {
            formatter: function () {
                let formattedValue;

                // Check if the series is 'Effective Duty Rate' and format it as a percentage
                if (this.series.name === 'Effective Duty Rate') {
                    formattedValue = `${this.y}%`; // Convert to percentage and format with 2 decimal places
                    // formattedValue = `${(this.y * 100).toFixed(2)}%`; // Convert to percentage and format with 2 decimal places
                } else {
                    // Use formatNumber for other series
                    formattedValue = `${currencySymbol || userCurrency?.symbol || "£"}${formatNumber(this.y)}`;
                }

                return `${this.series.name}: ${formattedValue}`;
            }
        },

        series: [{
            name: 'Total Duty Paid',
            data: totalDutyPaidSeries,
            yAxis: 0, // Total Duty Paid on the first axis
            color: "#D2042D"
        }, {
            name: 'Total Customs Value',
            data: totalCustomsValueSeries,
            yAxis: 1,// Total Customs Value on the second axis

        }, {
            name: 'Effective Duty Rate',
            data: effectiveDutyRateSeries, // Effective Duty Rate series
            yAxis: 2, // Effective Duty Rate on the third axis
            dashStyle: 'ShortDot',
            color: '#50C878'
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    yAxis: [{
                        tickAmount: 2,
                        title: {
                            x: 15,
                            reserveSpace: false
                        }
                    }, {
                        tickAmount: 2,
                        title: {
                            x: 20,
                            reserveSpace: false
                        }
                    }, {
                        tickAmount: 2,
                        title: {
                            x: -20,
                            reserveSpace: false
                        }
                    }]
                }
            }]
        }
    };



    if (isFetched && !newLineChartData.length) {
        return (
            <Box className="item-content" sx={{ height: '400px' }}>
                <Typography sx={{ textAlign: 'left', fontWeight: '600', padding: '5px 10px', fontSize: '20px' }}>
                    {widgetTitle}
                </Typography>
                <Box className="dashboard-item">
                    <Typography variant="h6" sx={{ textAlign: 'center', p: 20 }}>
                        No result found
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <div>
            <Box className={`item-content tooltip-outer viewChart ${isPrint ? "print" : ""}`}sx={{ height: '400px' }}>
                {pathType === '/analyticals' && viewHelpText ? null : ((pathType === '/analyticals') ? (
                    <Tooltip title={helpText} placement="right" className='info-tooltip'>
                        <IconButton sx={{ p: '0' }}>
                            <InfoIcon sx={{ color: '#000' }} />
                        </IconButton>
                    </Tooltip>
                ) : null
                )}
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
                {pathType === '/analyticals' && viewHelpText ? (
                    <p className='printInfoText'>{viewHelpText}</p>
                ) : null}
            </Box>
        </div>
    );
};

export default LineChartNewComponent;
