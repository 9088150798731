import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { authAPI } from "../../services/authAPI";

function AddUser() {
  const access_token = useSelector((state) => state.auth.access_token);
  const userProfile = useSelector((state) => state.userProfile);
  const [countryList, setCountryList] = useState([]);
  let navigate = useNavigate();
  const checkImpUser = JSON.parse(localStorage.getItem("impersonateUserIds"));
  
  // Get country list
  useEffect(() => {
    fetchCountryList();
  }, []);

  useEffect(() => {
    if (checkImpUser?.length > 1) {
      navigate("/");
    }
  }, [checkImpUser, navigate]);

  const fetchCountryList = async () => {
    try {
      const countries = await authAPI.countryList();
      setCountryList(countries);
    } catch (error) {
      console.error("Error fetching country list:", error);
    }
  };

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim()
      .required("First name can't be blank.")
      .matches(/^[a-zA-Z0-9_ ]{2,}$/, "First name cannot contain spaces."),
    last_name: Yup.string().trim()
      .required("Last name can't be blank.")
      .matches(/^[a-zA-Z0-9_ ]{2,}$/, "Last name cannot contain a special character."),
    email: Yup.string().trim()
      .email("Please enter a valid email address.")
      .required("Email can't be blank.")
      .trim(),
    company_name: Yup.string().required("Company name can't be blank.").trim(),
    country: Yup.string().required("Country can't be blank.").trim(),
    // zone: Yup.string().trim()
    //   .required("Zone can't be blank.")
    //   .matches(/^[a-zA-Z0-9_ ]{2,}$/, "Zone cannot contain spaces."),
    position: Yup.string().trim()
      .required("Position can't be blank.")
      // .matches(/^[a-zA-Z0-9_ ]{2,}$/, "Position cannot contain spaces."),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      company_name: userProfile.company.company_name,
      country: "",
      zone: "",
      position: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setSubmitting(true);
        if (access_token) {
          const response = await authAPI.addUser({
            access_token,
            first_name: values.first_name.trim(),
            last_name: values.last_name.trim(),
            email: values.email.trim(),
            company_name: userProfile.company.company_name,
            country: values.country,
            zone: values.zone.trim(),
            position: values.position.trim(),
          });
          if (response.message) {
            toast.success(response.message);
            resetForm();
            navigate("/view-user");
          } else if (response.error) {
            toast.error(response.error);
            resetForm();
          }
        }
      } catch (error) {
        console.log("Error updating user profile:", error);
        const message = "Something wrong !";
        toast.error(message);
        resetForm();
        formik.setFieldError(
          "newPassword",
          "Error updating password. Please try again."
        );
        setSubmitting(false);
      }
    },
  });

  const handleShowUserList = () => {
    navigate("/view-user");
  };

  return (
    <div>
      <div className="pagecontainer scrollablearea">
        <div className="columns-2">
          <h1 className="text-3xl font-medium mb-8">Add User</h1>
          <div className="text-right">
            <button className="button-default" onClick={handleShowUserList}>
              Users List
            </button>
          </div>
        </div>
        <div className="bg-white xl:p-16 md:p-6 sm:p-4 rounded-lg">
          <ToastContainer />
          <form onSubmit={formik.handleSubmit}>
            <div className="md:columns-2 sm:columns-1 xl:gap-14 sm:gap-6">
              <div className="w-full mb-3 sm:mb-2">
                <label className="mb-1 block">First Name:</label>
                <input
                  className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                  type="text"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.first_name && (
                  <div className="error-message">
                    {formik.errors.first_name}
                  </div>
                )}
              </div>

              <div className="w-full mb-3 sm:mb-2">
                <label className="mb-1 block">Last Name:</label>
                <input
                  className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                  type="text"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.last_name && (
                  <div className="error-message">{formik.errors.last_name}</div>
                )}
              </div>
            </div>
            <div className="md:columns-2 sm:columns-1 xl:gap-14 sm:gap-6">
              <div className="w-full mb-3 sm:mb-2">
                <label className="mb-1 block">Email:</label>
                <input
                  className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && (
                  <div className="error-message">{formik.errors.email}</div>
                )}
              </div>
              <div className="w-full mb-3 sm:mb-2">
                <label className="mb-1 block">Company Name:</label>
                <input
                  className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                  type="text"
                  name="company_name"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  disabled
                />
                {formik.errors.company_name && (
                  <div className="error-message">
                    {formik.errors.company_name}
                  </div>
                )}
              </div>
            </div>
            <div className="md:columns-2 sm:columns-1 xl:gap-14 sm:gap-6">
              <div className="w-full mb-3 sm:mb-2">
                <label className="mb-1 block">Country:</label>
                <select
                  className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                >
                  <option value="">Select a country</option>
                  {countryList.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {formik.errors.country && (
                  <div className="error-message">{formik.errors.country}</div>
                )}
              </div>
              <div className="w-full mb-3 sm:mb-2">
                <label className="mb-1 block">Zone:</label>
                <input
                  className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                  type="text"
                  name="zone"
                  value={formik.values.zone}
                  onChange={formik.handleChange}
                />
                {formik.errors.zone && (
                  <div className="error-message">{formik.errors.zone}</div>
                )}
              </div>
            </div>

            <div className="md:w-1/2 mb-3 xl:pr-7 md:pr-4 sm:w-full sm:pr-0">
              <label className="mb-1 block">Position:</label>
              <input
                className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                type="text"
                name="position"
                value={formik.values.position}
                onChange={formik.handleChange}
              />
              {formik.errors.position && (
                <div className="error-message">{formik.errors.position}</div>
              )}
            </div>
            <div className="text-right">
              <button
                className="button-default xl:my-5 sm:my-3"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? "Please wait..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
