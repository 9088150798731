import React, { useState } from "react";
import { Modal, Box, Typography, Button, TextField, } from "@mui/material";

import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//** local files */
import { authAPI } from "../../services/authAPI";
import "./CustomModal.css";

function FolderCreateModel({ openPopup, handleClose, fileId }) {
  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "200px",
    width: "400px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
    blur: "8px",
    opacity: 2,
    justifyItems: "center",
    alignItems: "center",
  };


  const [folderName, setFolderName] = useState('');
  const vaultItemId = localStorage.getItem('vaultItemId');
  const handleSubmitManager = async () => {
    // Trim the folderName to remove leading and trailing whitespace
    const trimmedFolderName = folderName.trim();
  
    if (!trimmedFolderName) {
      toast.error("Folder Name cannot be empty");
      return;
    }
    if ((!fileId || !fileId?.id) && !vaultItemId) {
      toast.error("File ID is required");
      return;
    }
  
    const data = {
      name: trimmedFolderName,
      folder_id: fileId?.id || vaultItemId
    };
  
    const response = await authAPI.CreateFolderFile({ access_token, data });
    if (response?.message) {
      toast.success(response?.message);
      handleClose();
    } else if (response?.error) {
      toast.error(response?.error?.message);
    }
  };

  const handleResetClose =() => {
    handleClose();
    setFolderName('')
  }

  return (
    <Modal
      open={openPopup}
      aria-labelledby="file-upload-modal"
      aria-describedby="file-upload-description"
    >
      <Box sx={style} elevation={8}>
        <Typography id="file-upload-modal" variant="h6" component="h2">
          Create Folder
        </Typography>
        <button className="close-button" onClick={handleResetClose}>
          &times;
        </button>
        <Box sx={{display:'flex'}}>
        <TextField
        sx={{width:'100%'}}
          name="folderName"
          label="Folder Name"
          variant="outlined"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
        />
        <Button variant="outlined" className="btn-orange" sx={{marginLeft:'10px', minWidth:'80px'}} onClick={handleSubmitManager}>Submit</Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default FolderCreateModel;
