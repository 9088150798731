import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Card, Grid, Typography, IconButton } from '@mui/material';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import React, { useState, useEffect, createRef, useRef } from 'react';
import BarChart3DComponent from './Charts/BarChart3DComponent';
import GaugeChart from './Charts/GaugeChart';
import InfoDisplay from './Charts/InfoDisplay';
import TableDataChart from './Charts/TableDataChart';
import LineChartComponent from './Charts/lineChartComponent';
import MapNewComponent from './Charts/newMapChart';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { toast } from 'react-toastify';
import logoPrint from '../../assets/images/logPrint.png';
import { authAPI } from '../../services/authAPI';
import { sleep } from '../common/errorhandling'
import LineChartNewComponent from './Charts/LineChartNewComponent';

const PrintDynamicGrid = ({ data, itemTitle, reportFormattedDate, filters, page = '', printSuccess }) => {
  const [filterValue, setFilterValue] = useState(filters || []);
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
  // const [printLoading, setPrintLoading] = useState(false);
  const [showPrint, setShowPrint] = useState(true);
  const ref = createRef();
  const hasFetched = useRef(false); // Use a ref to track if the API has been called

  useEffect(() => {
    fetchFilters()
  }, [])

  const fetchFilters = () => {
    const f = window.localStorage.getItem('filters')
    setFilterValue(JSON.parse(f))
  }
  useEffect(() => {
    window.addEventListener('filterUpdate', fetchFilters);

    return () => {
      window.removeEventListener('filterUpdate', fetchFilters);
    };
  }, []);

  useEffect(() => {
    if (showPrint) {
      setShowPrint(false);
      if (!hasFetched.current) {
        if (data?.length > 0) {
          printComponent();
        } else {
          toast.error('This report is blank and cannot be printed.')
          printSuccess(false)
        }
        hasFetched.current = true; // Set the flag to true after the first call
      }
    }
  }, [showPrint])

  const handleRemoveFilter = (index) => {
    const newFilters = filterValue.filter((_, i) => i !== index);
    setFilterValue(newFilters)
    window.localStorage.setItem('filters', JSON.stringify(newFilters));
    window.dispatchEvent(new Event('filterUpdate'));
  };

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <>
        <Box sx={{ flexGrow: 1, padding: 1 }} className="widgetView">
          <Box className="page-heading">
            <h1>
              {itemTitle} {reportFormattedDate}
            </h1>
          </Box>
        </Box>
        <Typography variant="body1" sx={{ textAlign: 'center', padding: 2 }}>
          No data available
        </Typography>
      </>
    );
  }

  const printComponent = async () => {
    if (showPrint) {
      setShowPrint(false);

      const component = ref.current;
      if (!component) {
        console.error('Component not found!');
        printSuccess(false)
        return;
      }
      await sleep(2000)
      try {
        const canvas = await html2canvas(component, {
          useCORS: true,
          allowTaint: true,
          backgroundColor: '#ffffff',
          scale: 2,
        });

        //const imgData = canvas.toDataURL('image/png');
        const logoDataUrl = logoPrint; // Your base64 string here

        const image = { type: 'jpeg', quality: 0.98 };
        const margin = [0.5, 0.5]; // Increased margin for better content padding

        const imgWidth = 8.3;
        const pageHeightNew = 9.3;

        const innerPageWidth = imgWidth - margin[0] * 2;
        const innerPageHeight = pageHeightNew - margin[1] * 2;

        const pxFullHeight = canvas.height;
        const pxPageHeight = Math.floor(canvas.width * (pageHeightNew / imgWidth));

        const nPages = Math.ceil(pxFullHeight / pxPageHeight);

        const pageCanvas = document.createElement('canvas');
        const pageCtx = pageCanvas.getContext('2d');
        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;

        const pdf = new jsPDF('p', 'in', [8.3, 11.7]);

        const logoWidth = 1; // Adjust the width of the logo
        const logoHeight = 0.9; // Adjust the height of the logo
        const logoX = margin[0]; // Positioning logo at top left
        const logoY = margin[0];

        for (let page = 0; page < nPages; page++) {
          if (page > 0) {
            pdf.addPage();
          }

          if (page === 0) {
            pdf.addImage(logoDataUrl, 'PNG', logoX, logoY, logoWidth, logoHeight);
          }

          if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
            pageCanvas.height = pxFullHeight % pxPageHeight;
          }

          const w = pageCanvas.width;
          const h = pageCanvas.height;

          pageCtx.fillStyle = 'white';
          pageCtx.fillRect(0, 0, w, h);
          pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

          const pageImgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
          pdf.addImage(pageImgData, image.type, margin[0], margin[1] + logoHeight + 0.3, innerPageWidth, page === nPages - 1 ? pageCanvas.height * innerPageWidth / pageCanvas.width : innerPageHeight);

          if (page > 0) {
            pdf.addImage(logoDataUrl, 'PNG', logoX, logoY, logoWidth, logoHeight); // Add logo on each page
          }
        }

        const pdfBlob = new Blob([pdf.output('arraybuffer')], { type: 'application/pdf' });

        // Convert the pdfBlob to base64
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = async function () {
          const base64data = reader.result.split(',')[1]; // Get the base64 string

          // Upload the base64 data to the server
          const formData = new FormData();
          formData.append('document_base64', base64data);
          formData.append('reportTitle', itemTitle);

          try {
            const response = await authAPI.uploadPrintDoc({ access_token, formData });
            if (response?.response === "success") {
              toast.success(response?.message || "Report saved successfully in the Vault menu under the 'C360 Analytical Tool Reports' folder.")
              printSuccess(true)
            } else if (response?.response === "fail") {
              toast.error(response?.message || 'Something went wrong');
              printSuccess(false)
            }

            const { url } = await response.json();

            // Open the uploaded PDF URL
            window.open(url, '_blank');
            setShowPrint(false);

          } catch (error) {
            console.error('Upload failed:', error);
            setShowPrint(false);
            printSuccess(false)
          }
        };
      } catch (error) {
        console.error('Error capturing the component:', error);
        setShowPrint(false);
        printSuccess(false)
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }} className="widgetView" ref={ref}>

      <Box className="page-heading">
        <h1>
          {itemTitle} {reportFormattedDate}
        </h1>
      </Box>
      <Box sx={{ pageBreakInside: 'auto', flexWrap: 'wrap' }}>
        {filterValue?.length > 0 ? (
          <Card sx={{ marginTop: '0' }}>
            {filterValue.map((filter, index) => (
              <Typography
                variant="body1"
                key={index}
                sx={{ padding: '5px 0px 5px 12px', fontSize: '14px' }}
              >
                Report Filters:
                {page !== 'report' ?
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveFilter(index)}
                    sx={{ float: "none" }}
                  >
                    <CancelIcon fontSize="small" sx={{ color: "#1a425f" }} />
                  </IconButton>
                  : <span>&emsp;</span>}
                {filter?.columnDisplay}:
                <strong>
                  {filter?.value?.value}
                </strong>
                {filter?.value?.dateType === 'lastmonth' ? (
                  <strong> Last Month</strong>
                ) : filter?.value?.dateType === 'thisquarter' ? (
                  <strong> This Quarter</strong>
                ) : filter?.value?.dateType === 'lastquarter' ? (
                  <strong> Last Quarter</strong>
                ) : filter?.value?.dateType === 'thisyear' ? (
                  <strong> This Year</strong>
                ) : filter?.value?.dateType === 'lastyear' ? (
                  <strong> Last Year</strong>
                ) : (
                  <strong> {filter?.value?.dateType}</strong>
                )}
                {filter?.value?.startDate && filter?.value?.endDate && (
                  <strong>
                    {moment(filter?.value?.startDate).format('DD/MM/YYYY')} to {moment(filter?.value?.endDate).format('DD/MM/YYYY')}
                  </strong>
                )}
              </Typography>
            ))}
          </Card>
        ) : (
          ''
          // <Typography variant='body1' sx={{ padding: '10px 0px 10px 12px', fontSize: '14px' }}>
          //   {viewCurrency ? `Currency: ${viewCurrency}` : 'No filters set'}
          // </Typography>
        )}

        {data?.map((row, rowIndex) => (
          <Grid container spacing={2} key={rowIndex} sx={{ marginTop: '0px' }} className='pageBreak'>
            {row?.map((item, itemIndex) => (
              <Grid item xs={12} sm={12} md={12 / row.length} key={itemIndex} sx={{ marginBottom: '5px' }} wrap={false} className='pageBreakinside'>
                <Box sx={{ borderRadius: 5, textAlign: 'center' }} className="printPage">
                  {rowIndex !== 0 && rowIndex % 3 === 0 &&
                    <Box className="page-heading"><h1 sx={{ margin: '0 0 10px' }}>&nbsp;</h1></Box>
                  }
                  {
                    ['widgets.imports.edr', 'widgets.imports.edrOnDutiable', 'widgets.imports.totalImports', 'widgets.imports.totalVatPaid', 'widgets.imports.totalImportVal', 'widgets.imports.totalVatValue', 'widgets.exports.exportsByEntryNo', 'widgets.imports.totalDutyPaid'].includes(item.name) &&
                    <InfoDisplay widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} itemTitle={itemTitle} helpText={item?.helpText} viewHelpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId={item?.properties[5]?.value?.value} eyeFlag={true} />
                  }

                  {['widgets.imports.zeroCommodityCodes', 'widgets.imports.dutyPaidByCurrency', 'widgets.imports.countryorigincomparedcountrydispatch'].includes(item.name) &&
                    <TableDataChart widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} viewHelpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId={item?.properties[5]?.value?.value} />
                  }

                  {['widgets.imports.totalCommodityCodesUsed', 'widgets.imports.edrbymonth', 'widgets.imports.importVatValue', 'widgets.imports.edrbymonthOnDutiable'].includes(item.name) &&
                    <LineChartComponent widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} viewHelpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId={item?.properties[5]?.value?.value} />
                  }

                  {['widgets.imports.edrbymonthcustom'].includes(item.name) &&
                    <LineChartNewComponent widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId={item?.properties[5]?.value?.value} />
                  }

                  {[
                    'widgets.imports.totalvatposp', 'widgets.imports.topCommodityCodesByDutyPaid', 'widgets.imports.suppliersInMultipleCurrencies', 'widgets.exports.totalcpc', 'widgets.imports.bottomCommodityCodesByDutyPaid', 'widgets.imports.topCommodityCodesByValue', 'widgets.imports.bottomCommodityCodesByValue', 'widgets.imports.topCurrenciesByImportVal', 'widgets.imports.topCurrenciesByDutyPaid', 'widgets.imports.topDeclarantsByCustVal', 'widgets.imports.topDeclarants', 'widgets.imports.importsByPref', 'widgets.imports.importsByCpc', 'widgets.imports.topSuppliersByDutyPaid', 'widgets.imports.topSuppliersByImportVal', 'widgets.imports.bottomSuppliersByImportVal', 'widgets.imports.importValByCpc', 'widgets.imports.importValByPref', 'widgets.imports.topSuppliersByEntryNosPerEntry', 'widgets.imports.topSuppliersByLineEntry', 'widgets.imports.suppliersByDispCtry', 'widgets.imports.suppliersByOrigCtry', 'widgets.imports.dutyRateByDispCtr', 'widgets.imports.dutyRateByDispCtry', 'widgets.imports.dutyRateByOrigCtry', 'widgets.exports.topCommodityCodesByValue', 'widgets.exports.bottomCommodityCodesByValue', 'widgets.exports.topCommCodes', 'widgets.exports.bottomCommCodes', 'widgets.exports.topCurrenciesByVolume', 'widgets.exports.topConsigneesInMultipleCurrencies', 'widgets.exports.exportsByConsignee', 'widgets.imports.importCountByEpu'
                  ].includes(item.name) &&
                    <BarChart3DComponent widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} viewHelpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId={item?.properties[5]?.value?.value} />
                  }

                  {['widgets.imports.totalVatValuevsVatPaid',].includes(item.name) &&
                    <GaugeChart widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} viewHelpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId={item?.properties[5]?.value?.value} />
                  }

                  {[
                    "widgets.imports.dutyPaidByDispCtry", "widgets.imports.dutyPaidByOrigCtry", "widgets.imports.importValByDispCtry", "widgets.imports.importValByOrigCtry", "widgets.imports.declarantsByDispCtry", "widgets.imports.declarantsByOrigCtry", "widgets.imports.importsByDispCtry", "widgets.exports.commodityCodesPerDestCtry", "widgets.exports.noOfExportsGoingToDestinations", "widgets.imports.importsByOrigCtry"
                  ].includes(item.name) &&
                    <MapNewComponent data={data} widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} viewHelpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId={item?.properties[5]?.value?.value} />
                  }
                </Box>
              </Grid>
            ))}
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default PrintDynamicGrid;
