import React, { useCallback } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
//** local files */
import { authAPI } from "../../services/authAPI";
import "./CustomModal.css";
function FileUploadModal({ openPopup, handleClose, fileId, onResponseGet }) {
  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "385px",
    width: "400px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
    blur: "8px",
    opacity: 2,
    justifyItems: "center",
    alignItems: "center",
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
    allowOutsideClick: false,
    showCloseButton: true,
  });
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const updatedFiles = Array.from(acceptedFiles).map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return file;
      });
      const data = new FormData();
      for (let i = 0; i < updatedFiles.length; i++) {
        data.append("file", updatedFiles[i]);
      }
      data.append("folder_id", fileId?.id || localStorage.getItem('vaultItemId'));
      const response = await authAPI.uploadSelectedFolderFile({
        access_token,
        data,
      });
      await onResponseGet(response);
    },
    [access_token, fileId?.id, onResponseGet]
  );
  function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
      bytes = bytes + " bytes";
    } else if (bytes == 1) {
      bytes = bytes + " byte";
    } else {
      bytes = "0 bytes";
    }
    return bytes;
  }
  // Function to validate file size
  function validateFileSize(filePath, maxSize, fileName) {
    const fileSize = filePath;
    const fileN = fileName

    if (fileSize > maxSize * 1048576) {
      swalWithBootstrapButtons.fire({
        title: "Size Alert!",
        text: `${fileN} File ${formatSizeUnits(
          filePath
        )} is too large. Maximum allowed size is ${maxSize} MB.`,
        icon: "warning",
      });
      console.log(
        `Error: File ${formatSizeUnits(
          filePath
        )} is too large. Maximum allowed size is ${maxSize} MB.`
      );
      process.exit(1);
    } else {
      console.log(
        `File ${formatSizeUnits(filePath)} size is valid: ${formatSizeUnits(
          fileSize
        )}`
      );
    }
  }

  const validationManager = (file) => {
    let size = validateFileSize(file.size, 16, file?.name);
    console.log("file", file, size);
  };
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".xls",
        ".csv",
      ],
      "application/msword": [".doc", ".docx"],
    },
    validator: validationManager,
  });
  const fileRejectionItems = fileRejections.map(({ file, errors }) =>
    errors.map((e) => console.log("erorrs", e))
  );
  return (
    <Modal
      open={openPopup}
      aria-labelledby="file-upload-modal"
      aria-describedby="file-upload-description"
    >
      <Box sx={style} elevation={8}>
        <Typography id="file-upload-modal" variant="h6" component="h2">
          Upload Files
        </Typography>
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
        <Box
          {...getRootProps({ className: "dropzone" })}
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F9F4EF",
            background:
              "linear-gradient(0deg, #F9F4EF, #F9F4EF) linear-gradient(0deg, #FF6B04, #FF6B04)",

            alignItems: "center",
            p: 2,
            border: "1px dashed orange",
            height: "272px",
            marginTop: "1rem",
            borderRadius: 2,
            cursor: "pointer",
          }}
          onClick={() => {
            // Add your file input click handler here
          }}
        >
          <input
            type="file"
            multiple
            onClick={(e) => e.preventDefault()}
            {...getInputProps()}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyItems: "center",
              alignItems: "center",
              marginTop: "3rem",
            }}
          >
            <Typography variant="body2" color="orange">
              Drag & Drop
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              marginLeft={"3px"}
            >
              your files here
            </Typography>
          </div>

          <input
            accept=".pdf,.xlsx,.png,.jpg,.jpeg,.doc,.docx"
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
          />

          <p>or</p>
          <p onClick={open}>Browse to upload files.</p>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ mt: 10 }}
            fontSize={"0.75rem"}
          >
            Only PDF, Excel, PNG, JPEG and doc formats with a file size of 16MB
          </Typography>
        </Box>
        {fileRejectionItems}
      </Box>
    </Modal>
  );
}

export default FileUploadModal;
