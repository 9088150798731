import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from 'highcharts/highcharts-3d';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { authAPI } from '../../../services/authAPI';
import { Box, CircularProgress, IconButton, Tooltip, Table, TableBody, TableCell, TableHead, TableRow, Typography, Dialog, DialogContent, DialogTitle, } from '@mui/material';
import { countryNameMapping } from '../AnalyticalCommon'
import InfoIcon from '@mui/icons-material/Info';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {getTitleforWidget} from "../../common/errorhandling"
import { useGlobalContext } from '../../../hook/globalstate';

Highcharts3D(Highcharts); // Initialize the 3D module

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BarChart3DComponent = ({ widgetName, widgetTitle, widgetData, leftItem = null, helpText, viewHelpText, hideInfo = false, dropKey, settingId, isPrint = false }) => {
    const name = widgetName;
    const popUpHeading = getTitleforWidget(widgetName)
    const [multipleCurrenciesData, setMultipleCurrenciesData] = useState(widgetData || null || []); // Change to null to handle loading state properly
    const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
    const hasFetched = useRef(false); // Use a ref to track if the API has been called
    const [loading, setLoading] = useState(false);
    const countryName = countryNameMapping()
    const [isFetched, setIsFetched] = useState(false); // State to track if fetching is complete
    const location = useLocation();
    const pathType = location.pathname;
    const [open, setOpen] = useState(false);
    const { state, setState } = useGlobalContext()
    const defCurrency = (widgetName == 'widgets.imports.topCommodityCodesByDutyPaid' || widgetName == 'widgets.imports.bottomCommodityCodesByDutyPaid' || widgetName == 'widgets.imports.topDeclarantsByCustVal' || widgetName == 'widgets.imports.importValByPref' || widgetName == 'widgets.imports.importValByCpc' || widgetName == 'widgets.imports.topSuppliersByImportVal' || widgetName == 'widgets.imports.topSuppliersByDutyPaid' || widgetName == 'widgets.exports.bottomCommodityCodesByValue' || widgetName == 'widgets.exports.topCommodityCodesByValue' || widgetName == 'widgets.exports.totalcpc' || widgetName == 'widgets.imports.totalvatposp') ? '£' : ''
    
    useEffect(() => {
        window.addEventListener('filterUpdate', fetchMultipleCurrencies);
        window.addEventListener('currencyUpdate', fetchMultipleCurrencies);

        return () => {
            window.removeEventListener('filterUpdate', fetchMultipleCurrencies);
            window.removeEventListener('currencyUpdate', fetchMultipleCurrencies);
        };
    }, []);
    
    const currencySymbol = window.localStorage.getItem('currencySymbol');
    const currencyToShow = currencySymbol && defCurrency !== '' ? currencySymbol : defCurrency
    const fetchMultipleCurrencies = async () => {
        setLoading(true);
        try {
            const filters = window.localStorage.getItem('filters');
            const viewCurrency = window.localStorage.getItem('viewCurrency');

            const formData = new FormData();
            formData.append('name', name);
            if (filters !== '' && filters !== null && filters !== undefined)
                formData.append('filters', filters);

            formData.append('viewCurrency', (viewCurrency || 'GBP')); // Use viewCurrency from localStorage

            const response = await authAPI.fetchInfoDisplayWidget({ access_token, formData });
            const dataWithFullNames = response?.result.map(item => ({
                ...item,
                tooltipName: countryName[item.tooltipName] || item.tooltipName
            }));

            setMultipleCurrenciesData(dataWithFullNames || []);
            setIsFetched(true);
            // toast.success(response?.message);
        } catch (error) {
            // toast.error(error?.message);
        }
        setLoading(false);
    };


    useEffect(() => {
        if (access_token && !hasFetched.current && !widgetData) {
            fetchMultipleCurrencies();
            hasFetched.current = true; // Set the flag to true after the first call
        }
        if (widgetData) {
            const dataWithFullNames = widgetData?.map(item => ({
                ...item,
                tooltipName: countryName[item.tooltipName] || item.tooltipName
            }));
            setMultipleCurrenciesData(dataWithFullNames);
        }
    }, [access_token, widgetData]);

    useEffect(() => {
        if (leftItem !== null && leftItem?.[0]?.item === widgetName) {
            fetchMultipleCurrencies();
        }
    }, [leftItem])

    if (loading) {
        return <div>
            <Box className={`item-content ${isPrint ? "print" : ""}`} sx={{ paddingTop: '160px', height: '400px' }}>
                <Box className="dashboard-item">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress sx={{ color: '#ff6b04' }} />
                    </Box>
                </Box>
            </Box>
        </div>;
    }

    if (!multipleCurrenciesData) {
        return null;
        // return <div>No data available</div>;
    }

    // Define custom colors
    const customColors = [
        '#647c89', '#4252b2', '#2796ef', '#00b767', '#adde36', '#9eac2f',
        '#e8db69', '#ff992a', '#ff5930', '#b32719', '#9633FF', '#96FF33'
    ];

    const displayedData = multipleCurrenciesData.slice(0, 20);
    const maxValue = Math.max(...displayedData.map(item => item.value));
    const yAxisMax = Math.ceil(maxValue / 10) * 10; // Adjusts to the nearest higher multiple of 10

    // Function to format the number
    function formatNumber(value) {
        return value?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 3
        });
    }

    let widgetSettings = state.settingData[`${settingId}-${dropKey}`];
    
    const options = {
        chart: {
            type: 'column',
            options3d: {
                enabled: true,
                alpha: 0,
                beta: 0,
                depth: 15
            },
            borderRadius: '.5rem', // Set the border radius here
        },
        accessibility: {
            enabled: false
        },
        title: {
            text: widgetSettings?.hideLabel ? '' : widgetSettings?.label ? widgetSettings.label : widgetTitle,
            align: 'left',
            fontSize: '10px',
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        plotOptions: {
            column: {
                depth: 15
            }
        },
        xAxis: {
            categories: displayedData.map(item => item.tooltipName || item.key),
            labels: {
                rotation: (name==='widgets.imports.totalvatposp') ? 0 : -60,
                skew3d: true,
                style: {
                    fontSize: '12px',
                },
                useHTML:false,
                overflow:'justify',
                formatter: function() {
                    return this.value
                    // return this.value.length > 20 ? `${this.value.substring(1,20)}<br/>${this.value.substring(21,this.value.length)}` : this.value
                }
            },
        },
        yAxis: {
            title: {
                text: '',
                margin: 20
            },
            // gridLineColor: 'transparent',
            // tickInterval: tickInterval,
            min: 0,
            max: yAxisMax,
            labels: {
                formatter: function() {
                  return `${(currencyToShow)}${(currencyToShow) ? formatNumber(this.value) : this.value}${widgetTitle?.indexOf('Avg') > -1 ? '%' : ''}`;
                }
              }
        },
        tooltip: {
            formatter: function () {
                const point = this.point;
                return `<b>${(point.tooltipName || point.key || point?.category)}</b><br/>${(currencyToShow)}${(currencyToShow) ? formatNumber(point.y) : point.y}${widgetTitle?.indexOf('Avg') > -1 ? '%' : ''} ${point?.valueList && widgetName === 'widgets.imports.suppliersInMultipleCurrencies' ? "("+point?.valueList+")" : ""}`;
            },
            className:'alignCenterItem'
        },
        series: [{
            name: 'Total imports',
            pointWidth: 20,  // Adjust this as needed (smaller width will fix overlap)
            data: displayedData.map((item, index) => ({
                y: item.value,
                tooltipName: item.tooltipName,
                valueList: item.valueList,
                color: customColors[index % customColors.length]
            }))
        }]
    };

    if ((isFetched && !displayedData?.length) || multipleCurrenciesData?.length === 0  ) {
        return (
            <Box className="item-content" sx={{ height: '400px' }}>
                <Typography sx={{ textAlign: 'left', fontWeight: '600', padding: '5px 10px', fontSize: '20px' }}>
                    {widgetTitle}
                </Typography>
                <Box className="dashboard-item">
                    <Typography variant="h6" sx={{ textAlign: 'center', p: 20 }}>
                        No result found
                    </Typography>
                </Box>
            </Box>
        );
    }

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>

            <Box className={`item-content tooltip-outer viewChart ${isPrint ? "print" : ""}`} sx={{ height: '400px' }}>
                {pathType === '/analyticals' && viewHelpText ? null : ((pathType === '/analyticals') ? (
                    <Tooltip title={helpText} placement="right" className='info-tooltip'>
                        <IconButton sx={{ p: '0' }}>
                            <InfoIcon sx={{ color: '#000' }} />
                        </IconButton>
                    </Tooltip>
                ) : null
                )}
                {multipleCurrenciesData?.length > 20 && (viewHelpText == '' || viewHelpText == null || viewHelpText == undefined) && (
                    <Tooltip className='detail-tooltip upload-btn' title="Click here to view all data" >
                        <IconButton onClick={handleClickOpen}>
                            <VisibilityIcon sx={{ color: '#ffffff' }} />
                        </IconButton>
                    </Tooltip>
                )}
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >

                    <DialogTitle sx={{ m: 0, p: 2, mr: '30px' }} id="customized-dialog-title">{widgetTitle}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#1f1f1f',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{popUpHeading?.popHeadingTitle || 'Title'}</TableCell>
                                    <TableCell>{popUpHeading?.popHeadingValue ? popUpHeading?.popHeadingValue :currencyToShow? 'Amount' : widgetTitle?.indexOf('Avg') > -1 ? 'Avg.' : 'Count'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {multipleCurrenciesData.map((currency, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{currency.tooltipName || currency.key}</TableCell>
                                        <TableCell>{(currencyToShow)}{(currencyToShow) ? formatNumber(currency.value) : currency.value}{widgetTitle?.indexOf('Avg') > -1 ? '%' : ''}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                </BootstrapDialog>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
                {pathType === '/analyticals' && viewHelpText ? (
                    <div className='printInfoText' >{viewHelpText}</div>
                ) : null}
            </Box>
        </div>
    );
};

export default BarChart3DComponent;
