import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { Box, CircularProgress, Grid, IconButton, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useEffect, useRef, useState } from 'react';
// import BookIcon from '@mui/icons-material/BookmarkBorder';
import MapIcon from '@mui/icons-material/Map';
import PollIcon from '@mui/icons-material/Poll';
import SpeedIcon from '@mui/icons-material/Speed';
import BookIcon from '@mui/icons-material/Stars';
import TableChartIcon from '@mui/icons-material/TableChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import { shallowEqual, useSelector } from "react-redux";
import { authAPI } from "../../services/authAPI";
import DraggableItems from './draggableItem.js';

const WidgetMenuReport = () => {
  const [searchValue, setSearchValue] = useState('');
  const [tabValue, setTabValue] = useState('import');
  const [favorites, setFavorites] = useState([]);
  const [folderName, setFolderName] = useState({});
  const [filteredWidgets, setFilteredWidgets] = useState({});
  const [loading, setLoading] = useState(false);
  const [expandedPanel, setExpandedPanel] = useState(null);
  const hasFetched = useRef(false); // Use a ref to track if the API has been called

  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);

  useEffect(() => {
    if (access_token && !hasFetched.current && tabValue) {
      fetchWidgetList(tabValue);
      hasFetched.current = true;
    }
  }, [access_token, tabValue]);

  /**
   * @function handleChange
   * @description Handles tab changes.
   * @param {object} event - The event object.
   * @param {string} newValue - The new tab value.
   */
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    hasFetched.current = false;
  };

  /**
   * @function fetchWidgetList
   * @description Fetches the list of widgets for import/export based on the selected tab.
   * @param {string} data - The type of data to fetch ('import' or 'export').
   */
  const fetchWidgetList = async (data) => {
    setLoading(true);
    const List = await authAPI.fetchImportExportWidget({ access_token, data });
    setFolderName(List?.result?.folder || {});
    setFilteredWidgets(List?.result?.folder || {});
    setLoading(false);
  };

  // Utility function to format folder names
  const formatFolderName = (folderName) => {
    // Capitalize the first letter
    folderName = folderName.charAt(0).toUpperCase() + folderName.slice(1);
    // Insert a space before each uppercase letter except the first one
    return folderName.replace(/([A-Z])/g, ' $1').trim();
  };


  /**
   * @function handleSearchFilter
   * @description Filters widgets based on the search value.
   * @param {object} e - The event object from the search input.
   */
  const handleSearchFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchValue(searchValue);

    const filter = Object.keys(folderName).reduce((result, folder) => {
      const matchedWidgets = folderName[folder].filter(widget =>
        widget.name.toLowerCase().includes(searchValue) ||
        (Array.isArray(widget.widgetName) && widget.widgetName.some(sub => sub.name.toLowerCase().includes(searchValue)))
      );

      if (folder.toLowerCase().includes(searchValue) || matchedWidgets.length > 0) {
        result[folder] = matchedWidgets;
      }

      return result;
    }, {});

    setFilteredWidgets(filter);
  };

  /**
   * @function handleAddToFavorites
   * @description Adds a widget to the favorites list.
   * @param {object} sub - The widget object to add to favorites.
   */
  const handleAddToFavorites = (sub) => {
    if (!favorites.find((fav) => fav.id === sub.id)) {
      setFavorites([...favorites, sub]);
    }
  };

  /**
   * @function handleRemoveToFavorites
   * @description Removes a widget from the favorites list.
   * @param {number} id - The ID of the widget to remove from favorites.
   */
  const handleRemoveToFavorites = (id) => {
    const updatedFavorites = favorites.filter((fav) => fav.id !== id);
    setFavorites(updatedFavorites);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };
  return (
 
      <Grid item m={1} mt={2}>
        <TextField className='filterbox' sx={{p:'0',width:'100%'}}
          type="search"
          placeholder="Filter..."
          value={searchValue}
          onChange={handleSearchFilter}
          variant="filled"
        />
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '15px' }}>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab sx={{ textTransform: 'capitalize' }} value="import" label="Imports" />
            <Tab sx={{ textTransform: 'capitalize' }} value="export" label="Exports" />
          </Tabs>
        </Box>
        {loading ? (
          <CircularProgress className="loader-vault" />
        ) : (
          <>
            <Accordion
              sx={{ boxShadow: 'none', margin:'0' }}
              // expanded={expandedPanel === 'favorites'}
              onChange={handleAccordionChange('favorites')}
              expanded={favorites?.length > 0}
            >
              <AccordionSummary
                sx={{ padding: '0px !important', margin:'0' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ marginTop: '0px',fontSize:'14px' }}>Favorites <span className='tag'>{favorites?.length}</span></Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ margin:'0' }}>
                {favorites?.map((favorite) => (
                  <Typography className='sidebar-item-content' key={favorite?.id} sx={{ cursor: 'all-scroll' }}>
                    {/* {favorite?.name} */}
                    <DraggableItems item={favorite?.widgetName} itemId={favorite?.id} name={favorite?.name} />
                    <Typography marginLeft={2} sx={{display:'flex'}}>
                      <Tooltip placement="right">
                        <IconButton sx={{ p: '0' }}>
                          <BookIcon sx={{ color: '#c78a9c' }} onClick={() => handleRemoveToFavorites(favorite?.id)} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={favorite?.helpText} placement="right">
                        <IconButton sx={{ p: '0' }}>
                          <InfoIcon sx={{ color: '#7e8c9c' }} />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Typography>
                ))}
              </AccordionDetails>
            </Accordion>
            {Object.keys(filteredWidgets).sort((a, b) => a.localeCompare(b)).map((folder) => (
              <Accordion
                key={folder}
                className='folderAccordion'
                sx={{ boxShadow: 'none',minHeight:'auto' }}
                expanded={expandedPanel === folder}
                onChange={handleAccordionChange(folder)}
              >
                <AccordionSummary
                  sx={{ padding: '0px !important', margin:'0' ,minHeight:'auto'}}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography sx={{ marginTop: '0px', fontSize:'14px',padding: '0px !important', margin:'0' }}>{formatFolderName(folder)} <span className='tag'>{filteredWidgets[folder].length}</span></Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: '0px !important', margin:'0' }}>
                  {filteredWidgets[folder].map((widget) => (
                    <Typography className='sidebar-item-content' key={widget?.id} sx={{ cursor: 'all-scroll' }}>
                      {(
                        
                        widget?.widgetName === 'widgets.imports.zeroCommodityCodes' ||
                        widget?.widgetName === 'widgets.imports.countryorigincomparedcountrydispatch' ||
                        widget?.widgetName === 'widgets.imports.dutyPaidByCurrency'
                      ) &&
                        <IconButton sx={{padding:'0'}}>
                          <TableChartIcon sx={{ color: '#7ab24c', fontSize: '20px' }} />
                        </IconButton>
                      }
                      {(
                        widget?.widgetName === 'widgets.imports.currencyCount' ||
                        widget?.widgetName === 'widgets.imports.edr' ||
                        widget?.widgetName === 'widgets.imports.edrOnDutiable' ||
                        widget?.widgetName === 'widgets.imports.totalImportVal' ||
                        widget?.widgetName === 'widgets.imports.totalImports' ||
                        widget?.widgetName === 'widgets.imports.supplierCount' ||
                        widget?.widgetName === 'widgets.imports.totalVatPaid' ||
                        widget?.widgetName === 'widgets.imports.totalVatValue' ||
                        widget?.widgetName === 'widgets.exports.numberOfCommCodes' ||
                        widget?.widgetName === 'widgets.exports.exportsByEntryNo' ||
                        widget?.widgetName === 'widgets.imports.totalDutyPaid' ||
                        widget?.widgetName === 'widgets.imports.averageClearance') &&
                        <IconButton sx={{padding:'0'}}>
                          <InfoIcon sx={{ color: '#7ab24c', fontSize: '20px' }} />
                        </IconButton>
                      }
                      {(
                        widget?.widgetName === 'widgets.imports.totalCommodityCodesUsed' ||
                        widget?.widgetName === 'widgets.imports.edrbymonth' ||
                        widget?.widgetName === 'widgets.imports.importVatValue'  ) &&
                        <IconButton sx={{padding:'0'}}>
                          <TimelineIcon sx={{ color: '#00bcd2', fontSize: '20px' }} />
                        </IconButton>}

                        {(
                        widget?.widgetName === 'widgets.imports.edrbymonthcustom' ) &&
                        <IconButton sx={{padding:'0'}}>
                          <TimelineIcon sx={{ color: '#00bcd2', fontSize: '20px' }} />
                        </IconButton>
                        }
                      {(
                        widget?.widgetName === "widgets.imports.dutyPaidByDispCtry" ||
                        widget?.widgetName === "widgets.imports.dutyPaidByOrigCtry" ||
                        widget?.widgetName === "widgets.imports.importValByDispCtry" ||
                        widget?.widgetName === "widgets.imports.importValByOrigCtry" ||
                        widget?.widgetName === "widgets.imports.declarantsByOrigCtry" ||
                        widget?.widgetName === "widgets.imports.importsByDispCtry" ||
                        widget?.widgetName === "widgets.exports.commodityCodesPerDestCtry" ||
                        widget?.widgetName === "widgets.exports.noOfExportsGoingToDestinations" ||
                        widget?.widgetName === "widgets.imports.declarantsByDispCtry" ||
                        widget?.widgetName === "widgets.imports.importsByOrigCtry"
                      ) &&
                      <IconButton sx={{padding:'0'}}>
                          <MapIcon sx={{ color: '#ffb32f', fontSize: '20px' }} />
                        </IconButton>}
                      {(
                        widget?.widgetName === 'widgets.imports.topCommodityCodesByDutyPaid' ||
                        widget?.widgetName === 'widgets.imports.bottomCommodityCodesByDutyPaid' ||
                        widget?.widgetName === 'widgets.imports.topCommodityCodesByValue' ||
                        widget?.widgetName === 'widgets.imports.bottomCommodityCodesByValue' ||
                        widget?.widgetName === 'widgets.imports.topCurrenciesByImportVal' ||
                        widget?.widgetName === 'widgets.imports.topCurrenciesByDutyPaid' ||
                        widget?.widgetName === 'widgets.imports.topDeclarantsByCustVal' ||
                        widget?.widgetName === 'widgets.imports.topDeclarants' ||
                        widget?.widgetName === 'widgets.imports.importsByPref' ||
                        widget?.widgetName === 'widgets.imports.importsByCpc' ||
                        widget?.widgetName === 'widgets.imports.topSuppliersByDutyPaid' ||
                        widget?.widgetName === 'widgets.imports.topSuppliersByImportVal' ||
                        widget?.widgetName === 'widgets.imports.bottomSuppliersByImportVal' ||
                        widget?.widgetName === 'widgets.imports.importValByCpc' ||
                        widget?.widgetName === 'widgets.imports.importValByPref' ||
                        widget?.widgetName === 'widgets.imports.topSuppliersByEntryNosPerEntry' ||
                        widget?.widgetName === 'widgets.imports.topSuppliersByLineEntry' ||
                        widget?.widgetName === 'widgets.imports.suppliersByDispCtry' ||
                        widget?.widgetName === 'widgets.imports.suppliersByOrigCtry' ||
                        widget?.widgetName === 'widgets.imports.dutyRateByDispCtr' ||
                        widget?.widgetName === 'widgets.imports.dutyRateByDispCtry' ||
                        widget?.widgetName === 'widgets.imports.dutyRateByOrigCtry' ||
                        widget?.widgetName === 'widgets.exports.topCommodityCodesByValue' ||
                        widget?.widgetName === 'widgets.exports.bottomCommodityCodesByValue' ||
                        widget?.widgetName === 'widgets.exports.topCommCodes' ||
                        widget?.widgetName === 'widgets.exports.bottomCommCodes' ||
                        widget?.widgetName === 'widgets.exports.topCurrenciesByVolume' ||
                        widget?.widgetName === 'widgets.exports.topConsigneesInMultipleCurrencies' ||
                        widget?.widgetName === 'widgets.exports.exportsByConsignee' ||
                        widget?.widgetName === 'widgets.exports.totalcpc' ||
                        widget?.widgetName === 'widgets.imports.suppliersInMultipleCurrencies' ||
                        widget?.widgetName === 'widgets.imports.totalvatposp' ||
                        widget?.widgetName === 'widgets.imports.importCountByEpu'
                      ) &&
                      <IconButton sx={{padding:'0'}}>
                          <PollIcon sx={{ color: '#009588', fontSize: '20px' }} />
                        </IconButton>}

                      {(
                        widget?.widgetName === 'widgets.imports.totalVatValuevsVatPaid' 
                       
                      ) &&  <IconButton sx={{padding:'0'}}>
                          <SpeedIcon sx={{ color: '#fa9941', fontSize: '20px' }} />
                        </IconButton>}

                      <DraggableItems item={widget?.widgetName} itemId={widget?.id} name={widget?.name} infoTitle={widget?.helpText} />
                      <Typography marginLeft={1} className='icon-tip' sx={{display:'flex'}}>
                        <Tooltip placement="right" className='heart'>
                          <IconButton sx={{ p: '0' }} onClick={() => handleAddToFavorites(widget)}>
                            <BookIcon sx={{ color: '#c78a9c' }} />
                          </IconButton>
                        </Tooltip>
                        {/* <Tooltip title={widget?.helpText} placement="right" className='info'>
                          <IconButton sx={{ p: '0' }}>
                            <InfoIcon sx={{ color: '#7e8c9c' }} />
                          </IconButton>
                        </Tooltip> */}
                      </Typography>
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </Grid>
   
  );
};

export default WidgetMenuReport;