import { createSlice } from "@reduxjs/toolkit";
import { clearUserProfile } from "./userProfileSlice";
const initialState = {
  access_token: null, // Initially, access_token is null
  refresh_token: null,
  payload_data: null,
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.access_token = action.payload.access;
      state.refresh_token = action.payload.refresh;
    },
    setUserToken: (state, action) => {
      state.access_token = action.payload.access;
      state.refresh_token = action.payload.refresh;
      state.payload_data = action.payload.payload_data;
    },
    loginSuccess: (state) => {
      state.isLoggedIn = true;
    },
    logoutSuccess: () => initialState,
    unSetUserToken: (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      state.payload_data = action.payload.payload_data;
    },
  },
});

export const signOutManager = () => {
  const userProfile = JSON.parse(localStorage.getItem("persist:userProfile"));
  const userId = userProfile?.id;

  const impersonateUserIds = localStorage.getItem("impersonateUserIds");
    // Parse the existing array from localStorage
    let arr = JSON.parse(impersonateUserIds);
    // Check if selectedUser?.id is already in the array to avoid duplicates
    if (userId && !arr?.includes(userId)) {
      arr?.pop(userId);
    }
  // Store the updated array back to localStorage
  localStorage.setItem("impersonateUserIds", JSON.stringify(arr));

  return async (dispatch) => {
    dispatch(logoutSuccess());
    dispatch(clearUserProfile());
    dispatch(unSetUserToken({ access_token: null, refresh_token: null }));
    localStorage.removeItem("persist:userProfile");
    localStorage.removeItem("persist:auth");
    localStorage.removeItem("persist:message");
    localStorage.removeItem(
      "CognitoIdentityServiceProvider.6b5oi1n38rrrseit6i3uje9s0f.f9601c22-2b07-4189-8de4-4f3b46b79052.idToken"
    );
    localStorage.removeItem(
      "CognitoIdentityServiceProvider.6b5oi1n38rrrseit6i3uje9s0f.f9601c22-2b07-4189-8de4-4f3b46b79052.accessToken"
    );
    localStorage.removeItem(
      "CognitoIdentityServiceProvider.6b5oi1n38rrrseit6i3uje9s0f.f9601c22-2b07-4189-8de4-4f3b46b79052.refreshToken"
    );
    localStorage.removeItem(
      "CognitoIdentityServiceProvider.6b5oi1n38rrrseit6i3uje9s0f.f9601c22-2b07-4189-8de4-4f3b46b79052.clockDrift"
    );
    localStorage.removeItem(
      "CognitoIdentityServiceProvider.6b5oi1n38rrrseit6i3uje9s0f.LastAuthUser"
    );
    localStorage.removeItem(
      "CognitoIdentityServiceProvider.6b5oi1n38rrrseit6i3uje9s0f.f9601c22-2b07-4189-8de4-4f3b46b79052.userData"
    );
  };
};

export const {setAccessToken, setUserToken, unSetUserToken, loginSuccess, logoutSuccess } =
  authSlice.actions;

export default authSlice.reducer;
