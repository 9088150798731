import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer>
      <div className="iso-certified">
        <div>ICO Registration: ZB005872</div>
      </div>
      <div className="copyright">© {year}, Customs Connect Digital Solutions ltd.</div>
      {/* <div className="footer-link">Terms and Privacy</div> */}
      <div
        className="certificatebyqms"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={require("../../assets/images/ISO270012013badgewhite.png")}
          alt="iso-certified"
          style={{ height: "65px", width: "auto" }}
        />
        <img
          src={require("../../assets/images/ISO90012015badgewhite.png")}
          alt="iso-certified"
          style={{ height: "65px", width: "auto" }}
        />
      </div>
    </footer>
  );
  
};

export default Footer;
