import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { authAPI } from "../../../services/authAPI";
import { useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { getTitleforWidget } from "../../common/errorhandling";




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const InfoDisplay = ({
  widgetName,
  widgetTitle,
  widgetData,
  leftItem = null,
  helpText,
  viewHelpText,
  hideInfo = false,
  eyeFlag = false,
  isPrint = false
}) => {
  const name = widgetName;
  const title = widgetTitle;
  const popUpHeading = getTitleforWidget(widgetName);
  const [loading, setLoading] = useState(false);
  const [infoDisplayData, setInfoDisplayData] = useState(widgetData || null);
  const [open, setOpen] = useState(false);
  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );
  const hasFetched = useRef(false);
  const location = useLocation();
  const pathType = location.pathname;
  // Function to format the number
  function formatNumber(value) {
    return value?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    });
  }

  useEffect(() => {
    window.addEventListener("filterUpdate", fetchInfoDisplay);
    window.addEventListener("currencyUpdate", fetchInfoDisplay);

    return () => {
      window.removeEventListener("filterUpdate", fetchInfoDisplay);
      window.removeEventListener("currencyUpdate", fetchInfoDisplay);
    };
  }, []);

  const currencySymbol = window.localStorage.getItem("currencySymbol");
  const fetchInfoDisplay = async () => {
    setLoading(true);
    try {
      const filters = window.localStorage.getItem("filters");
      const viewCurrency = window.localStorage.getItem("viewCurrency");
      const formData = new FormData();
      formData.append("name", name);
      if (filters !== "" && filters !== null && filters !== undefined)
        formData.append("filters", filters);

      formData.append("viewCurrency", viewCurrency || "GBP"); // Use viewCurrency from localStorage

      const response = await authAPI.fetchInfoDisplayWidget({
        access_token,
        formData,
      });
      setInfoDisplayData(response?.result || {});
    } catch (error) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (access_token && !hasFetched.current && !widgetData) {
      fetchInfoDisplay();
      hasFetched.current = true;
    }
  }, [access_token, widgetData]);

  useEffect(() => {
    if (leftItem !== null && leftItem?.[0]?.item === widgetName) {
      fetchInfoDisplay();
    }
  }, [leftItem]);

  if (loading) {
    return (
      <Box
      className={`item-content ${isPrint ? "print" : ""}`}
        sx={{ paddingTop: "160px", height: "400px", position: 'relative' }}
      >
        <Box className="dashboard-item">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ color: "#ff6b04" }} />
          </Box>
        </Box>
      </Box>
    );
  }

  if (!infoDisplayData) {
    return null; // Render nothing if data is not yet fetched
  }

  const currencyList = infoDisplayData?.results || [];
  let displayValue =
    typeof infoDisplayData === "object" && infoDisplayData !== null
      ? JSON.stringify(infoDisplayData?.data)
      : infoDisplayData;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (
    (infoDisplayData?.data === 0 && currencyList?.length === 0) ||
    (infoDisplayData?.results && currencyList?.length === 0)
  ) {
    return (
      <Box className="info-display item-content" sx={{ height: "400px" }}>
        {/* <Typography sx={{ textAlign: 'left', fontWeight: '600', padding: '5px 10px', fontSize: '20px' }}>
          {widgetTitle}
        </Typography> */}
        <Box className="dashboard-item">
          <Typography variant="h6" sx={{ textAlign: "center", p: 20 }}>
            No result found
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      className={`info-display item-content tooltip-outer viewChart ${isPrint ? "print" : ""}`}
      sx={{ height: "400px" }}
    >
      {pathType === "/analyticals" && viewHelpText ? null : pathType ===
        "/analyticals" ? (
        <Tooltip title={helpText} placement="right" className="info-tooltip">
          <IconButton sx={{ p: "0" }}>
            <InfoIcon sx={{ color: "#000" }} />
          </IconButton>
        </Tooltip>
      ) : null}
      <Box className="info-display-value">
        {displayValue !== undefined ? (
          name === "widgets.imports.edr" ||
            name === "widgets.imports.edrOnDutiable" ? (
            `${formatNumber(displayValue)}%`
          ) : name === "widgets.imports.totalVatValue" ||
            name === "widgets.imports.totalDutyPaid" ||
            name === "widgets.imports.totalVatPaid" ||
            name === "widgets.imports.totalImportVal" ? (
            `${currencySymbol || "£"}${formatNumber(displayValue)}`
          ) : (
            //`${formatNumber(displayValue)}`
            `${displayValue}`
          )
        ) : (
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            No result found
          </Typography>
        )}
      </Box>
      <Box className="info-display-text">{title}</Box>
      {currencyList.length > 0 && (
        <Box className="info-display-subtitle" sx={{ background: 'none' }}>
          {currencyList.length > 4 ? (
            <>
              {currencyList.slice(0, 0).join(", ")}
              {!eyeFlag && (
                <Tooltip
                  title={"Click here to view all data"}
                  placement="right"
                  className="info detail-tooltip upload-btn"
                >

                  <IconButton onClick={handleClickOpen}>
                    <VisibilityIcon sx={{ color: "#f36c2d" }} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            currencyList.join(", ")
          )}
        </Box>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ width: "95%" }}>{title} </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#1f1f1f",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {popUpHeading?.popHeadingTitle || "Title"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currencyList.map((currency, index) => (
                <TableRow key={index}>
                  <TableCell>{currency}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </BootstrapDialog>
      {pathType === "/analyticals" && viewHelpText ? (
        <div className="printInfoText">{viewHelpText}</div>
      ) : null}
    </Box>

  );
};

export default InfoDisplay;
