import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { authAPI } from "../../../services/authAPI";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { countryList } from "../AnalyticalCommon";
import { useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { useGlobalContext } from '../../../hook/globalstate';

const TableDataChart = ({
  widgetName,
  widgetTitle,
  widgetData,
  leftItem = null,
  helpText,
  viewHelpText,
  hideInfo = false,
  dropKey,
  settingId,
  isPrint = false,
  currencyCode
}) => {
  const location = useLocation();
  const pathType = location.pathname;
  const name = widgetName;
  const title = widgetTitle;
  const [loading, setLoading] = useState(false);
  const [tableChartData, setTableChartData] = useState(
    widgetData || null || []
  ); // Change to null to handle loading state properly
  const [isFetched, setIsFetched] = useState(false); // State to track if fetching is complete
  const { state, setState } = useGlobalContext()
  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );
  const userProfile = useSelector((state) => state.userProfile);
  const hasFetched = useRef(false); // Use a ref to track if the API has been called
  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const countryFullName = countryList();
  const [userCurrency, setUserCurrency] = useState();
    
      const fetchCurrencies = useCallback(async () => {
        try {
          const currenciesData = await authAPI.getCurrencies({access_token});
         
          const widgetCurrency = currenciesData.find(
            (ct) => ct.currencyCode === currencyCode
          );
    
          const userCurrency = currenciesData.find(
            (ct) => ct.currencyCode === userProfile.currency
          );
          setUserCurrency(widgetCurrency || userCurrency || "");
        } catch (error) {
          console.error("Error fetching country list:", error);
        }
      }, [userProfile.currency]);
    
      useEffect(() => {
        fetchCurrencies();
      }, [fetchCurrencies]);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  useEffect(() => {
    window.addEventListener("filterUpdate", fetchTableData);
    window.addEventListener("currencyUpdate", fetchTableData);

    return () => {
      window.removeEventListener("filterUpdate", fetchTableData);
      window.removeEventListener("currencyUpdate", fetchTableData);
    };
  }, []);

  const currencySymbol = window.localStorage.getItem("currencySymbol");
  const fetchTableData = async () => {
    setLoading(true);
    try {
      const filters = window.localStorage.getItem("filters");
      const viewCurrency = window.localStorage.getItem("viewCurrency");
      const formData = new FormData();
      formData.append("name", name);
      if (filters !== "" && filters !== null && filters !== undefined)
        formData.append("filters", filters || []);
      formData.append("viewCurrency", (viewCurrency || (currencyCode !== 'null' ? currencyCode : '') || userProfile?.currency || "GBP"));
      formData.append("userCurrency", (userProfile?.currency || "GBP"));
      const response = await authAPI.fetchInfoDisplayWidget({
        access_token,
        formData,
      });
      // toast.success(response?.message);
      setTableChartData(response?.result || []);
      setIsFetched(true); // Set the fetching complete state to true
    } catch (error) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

          useEffect(() => {
            if (currencyCode) {
              fetchTableData();
            }
          }, [currencyCode]);

    useEffect(() => {
      if (window.localStorage.getItem("viewCurrency") || userProfile?.currency) {
        fetchTableData();
      }
    }, [window.localStorage.getItem("viewCurrency"), userProfile?.currency]);

  useEffect(() => {
    if (access_token && !hasFetched.current && !widgetData) {
      fetchTableData();
      hasFetched.current = true; // Set the flag to true after the first call
    }
  }, [access_token, widgetData]);

  // Function to format the number
  function formatNumber(value) {
    return value?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  useEffect(() => {
    if (leftItem !== null && leftItem?.[0]?.item === widgetName) {
      fetchTableData();
    }
  }, [leftItem]);

  let widgetSettings = state.settingData[`${settingId}-${dropKey}`];

  if (loading) {
    return (
      <div>
        <Box
          className={`item-content ${isPrint ? "print" : ""}`}
          sx={{ paddingTop: "160px", height: "400px" }}
        >
          <Box className="dashboard-item">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress sx={{ color: "#ff6b04" }} />
            </Box>
          </Box>
        </Box>
      </div>
    );
  }

  const handleClickOpen = (row) => {
    setSelectedRowData(row); // Set the selected row data
    setOpen(true); // Open the dialog
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedRowData(null); // Clear the selected row data on close
  };
  
  return (
    <div>
      <Box
        className={`item-content tooltip-outer viewChart ${isPrint ? "print" : ""}`}
        sx={{ height: "400px", paddingBottom: "60px" }}
      >
        {pathType === "/analyticals" &&
          viewHelpText !== "" &&
          viewHelpText !== undefined &&
          viewHelpText !== null ? null : pathType === "/analyticals" ? (
            <Tooltip title={helpText} placement="right" className="info-tooltip">
              <IconButton sx={{ p: "0" }}>
                <InfoIcon sx={{ color: "#000" }} />
              </IconButton>
            </Tooltip>
          ) : null}
        <Box className="dashboard-item">
          {(isFetched && tableChartData?.length === 0 ) || tableChartData?.length === 0 ? (
            <Box className="" sx={{ height: '400px' }}>
            <Typography sx={{ textAlign: 'left', fontWeight: '600', padding: '5px 10px', fontSize: '20px' }}>
                {widgetTitle}
            </Typography>
            <Box className="dashboard-item">
                <Typography variant="h6" sx={{ textAlign: 'center', p: 20 }}>
                    No result found
                </Typography>
            </Box>
        </Box>
          ) : (
            <div className="tableWidgetParent">
              {tableChartData?.length ? <Box className="series-table-container">
                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    padding: "5px 10px",
                    fontSize: "20px",
                  }}
                >
                  {widgetSettings?.hideLabel ? '' : widgetSettings?.label ? widgetSettings.label : widgetTitle}
                </Typography>

                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="commodity codes table"
                  >
                    <TableHead>
                      <TableRow className="mat-header-row">
                        <>
                          <TableCell className="mat-header-cell">
                            Country of dispatch
                          </TableCell>
                          <TableCell className="mat-header-cell">
                            Country of origin
                          </TableCell>
                          <TableCell className="mat-header-cell">EDR</TableCell>
                          <TableCell className="mat-header-cell">
                            View
                          </TableCell>
                        </>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableChartData?.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {countryFullName[row?.dispCtry] || row?.dispCtry}
                            </TableCell>
                            <TableCell>
                              {countryFullName[row?.origCtry] || row?.origCtry}
                            </TableCell>{" "}
                            {/* Mapping the country code to the full name */}
                            <TableCell>{row?.edr} %</TableCell>
                            <TableCell>
                              <Tooltip
                                className="detail-tooltips upload-btn"
                                title="Click here to view all data"
                              >
                                {/* { viewHelpText == '' || viewHelpText == null || viewHelpText == undefined && ( */}
                                <IconButton
                                  onClick={() => handleClickOpen(row)}
                                >
                                  <VisibilityIcon sx={{ color: "#7f7f7f" }} />
                                </IconButton>
                                {/* )} */}
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={open}
                  maxWidth={'lg'}
                  fullWidth
                >
                  <DialogTitle
                    sx={{ m: 0, p: 2, mr: "30px", fontSize: "22px" }}
                    id="customized-dialog-title"
                  >
                    {widgetTitle}
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: "#1f1f1f",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent>
                    {selectedRowData && (
                      <Table sx={{ minWidth: "800px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell className="mat-header-cell">
                              Country of dispatch
                            </TableCell>
                            <TableCell className="mat-header-cell">
                              Country of origin
                            </TableCell>
                            <TableCell className="mat-header-cell" sx={{ width: '80px' }}>
                              EDR
                            </TableCell>
                            <TableCell className="mat-header-cell">
                              Commodity Code
                            </TableCell>
                            <TableCell className="mat-header-cell">
                              Customs Value
                            </TableCell>
                            <TableCell className="mat-header-cell">
                              Declarant EORI
                            </TableCell>
                            <TableCell className="mat-header-cell">
                              Declarant Name
                            </TableCell>
                            <TableCell className="mat-header-cell">
                              Entry Date
                            </TableCell>
                            <TableCell className="mat-header-cell">
                              Entry Identifier
                            </TableCell>
                            <TableCell className="mat-header-cell">
                              Preference Code
                            </TableCell>
                            <TableCell className="mat-header-cell">
                              Duty Paid
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{countryFullName[selectedRowData?.dispCtry]}</TableCell>
                            <TableCell>{countryFullName[selectedRowData?.origCtry]}</TableCell>
                            <TableCell>{selectedRowData?.edr} %</TableCell>
                            <TableCell>{selectedRowData?.Cmdtycode}</TableCell>
                            <TableCell>
                              {currencySymbol || userCurrency?.symbol || "£"}{formatNumber(selectedRowData?.CustomsValue)}
                              {/* {selectedRowData?.CustomsValue} */}
                            </TableCell>
                            <TableCell>
                              {selectedRowData?.DeclarantEORI}
                            </TableCell>
                            <TableCell>
                              {selectedRowData?.DeclarantName}
                            </TableCell>
                            <TableCell>
                              {selectedRowData?.EntryDate ? new Date(selectedRowData.EntryDate).toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric'
                              }) : ''}
                            </TableCell>

                            <TableCell>
                              {selectedRowData?.EntryIdentifier}
                            </TableCell>
                            <TableCell>
                              {selectedRowData?.Preferencecode}
                            </TableCell>
                            <TableCell>
                              {selectedRowData?.DutyPaid ? (currencySymbol || userCurrency?.symbol || "£") + formatNumber(selectedRowData.DutyPaid) : ''}                   
                              {/* {selectedRowData?.DutyPaid} */}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    )}
                  </DialogContent>
                </BootstrapDialog>
              </Box> : null}

              {pathType === "/analyticals" && viewHelpText ? (
                <p className="printInfoText">{viewHelpText}</p>
              ) : null}
            </div>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default TableDataChart;
